<template>
  <div class="head_top">
    <img src="../../../../public/images/二级海报/新闻中心.jpg" lazy width="100%" height="100%">

    <div class="xw_box">
      <div class="back">
        <img src="../../../../public/images/底部导航/关于我们/新闻/用什么来记录孩子的真善美？/图片6.png" lazy width="100%" height="100%">
      </div>
      <div class="xw_tite">
        <h2 @click="xw1">用什么来记录孩子的真善美？</h2>
        <div>Date: 2021/07/06</div>
        <p class="cc">孩子是父母奋斗的动力，为了孩子能有更好的未来，父母不得不走上工作岗位。然而，父母内心其实惦记着孩子的，尤其是处于幼儿阶段的宝宝哪怕只是远远的看着也特别满足，
          只差给孩子360度装上监控摄像头了，甚至想时刻跟孩子视频语音聊天。在这里，我们专业为您提供关爱的窗口---智能网络摄像机，让陪伴与呵护同在。智能</p>
      </div>
    </div>

<!--    <div id="box">-->
<!--      <div class="box" v-infinite-scroll="load" infinite-scroll-disabled="disabled" >-->
<!--        <ul class="list" >-->
<!--          <li v-for="(i,index) in list" class="list-item" :key="index">{{ i.noticeTitle }}</li>-->
<!--        </ul>-->
<!--        <p v-if="loading" style="margin-top:10px;" class="loading">-->
<!--          <span></span>-->
<!--        </p>-->
<!--        <p v-if="noMore" style="margin-top:10px;font-size:13px;color:#ccc">没有更多了</p>-->
<!--      </div>-->
<!--    </div>-->



  </div>
</template>

<script>
export default {
  name: "新闻",
  data() {
    return {
      count: 0,//起始页数值为0
      loading: false,
      totalPages: "2",//取后端返回内容的总页数
      list: [
        {
          title: '用什么来记录孩子的真善美',
          date: '2021-5-5',
          nei: "孩子是父母奋斗的动力，为了孩子能有更好的未来，父母不得不走上工作岗位。然而，父母内心其实惦记着孩子的，尤其是处于幼儿阶段的宝宝哪怕只是远远的看着也特别满足，\n" +
              "            只差给孩子360度装上监控摄像头了，甚至想时刻跟孩"
        },
        {
          title: '用什么来记录孩子的真善美',
          date: 2021 - 5 - 5,
          nei: "孩子是父母奋斗的动力，为了孩子能有更好的未来，父母不得不走上工作岗位。然而，父母内心其实惦记着孩子的，尤其是处于幼儿阶段的宝宝哪怕只是远远的看着也特别满足，\n" +
              "            只差给孩子360度装上监控摄像头了，甚至想时刻跟孩"
        },
        {
          title: '用什么来记录孩子的真善美',
          date: 2021 - 5 - 5,
          nei: "孩子是父母奋斗的动力，为了孩子能有更好的未来，父母不得不走上工作岗位。然而，父母内心其实惦记着孩子的，尤其是处于幼儿阶段的宝宝哪怕只是远远的看着也特别满足，\n" +
              "            只差给孩子360度装上监控摄像头了，甚至想时刻跟孩"
        },
        {
          title: '用什么来记录孩子的真善美',
          date: 2021 - 5 - 5,
          nei: "孩子是父母奋斗的动力，为了孩子能有更好的未来，父母不得不走上工作岗位。然而，父母内心其实惦记着孩子的，尤其是处于幼儿阶段的宝宝哪怕只是远远的看着也特别满足，\n" +
              "            只差给孩子360度装上监控摄像头了，甚至想时刻跟孩"
        },
        {
          title: '用什么来记录孩子的真善美',
          date: 2021 - 5 - 5,
          nei: "孩子是父母奋斗的动力，为了孩子能有更好的未来，父母不得不走上工作岗位。然而，父母内心其实惦记着孩子的，尤其是处于幼儿阶段的宝宝哪怕只是远远的看着也特别满足，\n" +
              "            只差给孩子360度装上监控摄像头了，甚至想时刻跟孩"
        },
        {
          title: '用什么来记录孩子的真善美',
          date: 2021 - 5 - 5,
          nei: "孩子是父母奋斗的动力，为了孩子能有更好的未来，父母不得不走上工作岗位。然而，父母内心其实惦记着孩子的，尤其是处于幼儿阶段的宝宝哪怕只是远远的看着也特别满足，\n" +
              "            只差给孩子360度装上监控摄像头了，甚至想时刻跟孩"
        },
        {
          title: '用什么来记录孩子的真善美',
          date: 2021 - 5 - 5,
          nei: "孩子是父母奋斗的动力，为了孩子能有更好的未来，父母不得不走上工作岗位。然而，父母内心其实惦记着孩子的，尤其是处于幼儿阶段的宝宝哪怕只是远远的看着也特别满足，\n" +
              "            只差给孩子360度装上监控摄像头了，甚至想时刻跟孩"
        },
        {
          title: '用什么来记录孩子的真善美',
          date: 2021 - 5 - 5,
          nei: "孩子是父母奋斗的动力，为了孩子能有更好的未来，父母不得不走上工作岗位。然而，父母内心其实惦记着孩子的，尤其是处于幼儿阶段的宝宝哪怕只是远远的看着也特别满足，\n" +
              "            只差给孩子360度装上监控摄像头了，甚至想时刻跟孩"
        },
        {
          title: '用什么来记录孩子的真善美',
          date: 2021 - 5 - 5,
          nei: "孩子是父母奋斗的动力，为了孩子能有更好的未来，父母不得不走上工作岗位。然而，父母内心其实惦记着孩子的，尤其是处于幼儿阶段的宝宝哪怕只是远远的看着也特别满足，\n" +
              "            只差给孩子360度装上监控摄像头了，甚至想时刻跟孩"
        },
        {
          title: '用什么来记录孩子的真善美',
          date: 2021 - 5 - 5,
          nei: "孩子是父母奋斗的动力，为了孩子能有更好的未来，父母不得不走上工作岗位。然而，父母内心其实惦记着孩子的，尤其是处于幼儿阶段的宝宝哪怕只是远远的看着也特别满足，\n" +
              "            只差给孩子360度装上监控摄像头了，甚至想时刻跟孩"
        },
        {
          title: '用什么来记录孩子的真善美',
          date: 2021 - 5 - 5,
          nei: "孩子是父母奋斗的动力，为了孩子能有更好的未来，父母不得不走上工作岗位。然而，父母内心其实惦记着孩子的，尤其是处于幼儿阶段的宝宝哪怕只是远远的看着也特别满足，\n" +
              "            只差给孩子360度装上监控摄像头了，甚至想时刻跟孩"
        },
        {
          title: '用什么来记录孩子的真善美',
          date: 2021 - 5 - 5,
          nei: "孩子是父母奋斗的动力，为了孩子能有更好的未来，父母不得不走上工作岗位。然而，父母内心其实惦记着孩子的，尤其是处于幼儿阶段的宝宝哪怕只是远远的看着也特别满足，\n" +
              "            只差给孩子360度装上监控摄像头了，甚至想时刻跟孩"
        },
        {
          title: '用什么来记录孩子的真善美',
          date: 2021 - 5 - 5,
          nei: "孩子是父母奋斗的动力，为了孩子能有更好的未来，父母不得不走上工作岗位。然而，父母内心其实惦记着孩子的，尤其是处于幼儿阶段的宝宝哪怕只是远远的看着也特别满足，\n" +
              "            只差给孩子360度装上监控摄像头了，甚至想时刻跟孩"
        },
        {
          title: '用什么来记录孩子的真善美',
          date: 2021 - 5 - 5,
          nei: "孩子是父母奋斗的动力，为了孩子能有更好的未来，父母不得不走上工作岗位。然而，父母内心其实惦记着孩子的，尤其是处于幼儿阶段的宝宝哪怕只是远远的看着也特别满足，\n" +
              "            只差给孩子360度装上监控摄像头了，甚至想时刻跟孩"
        },
        {
          title: '用什么来记录孩子的真善美',
          date: 2021 - 5 - 5,
          nei: "孩子是父母奋斗的动力，为了孩子能有更好的未来，父母不得不走上工作岗位。然而，父母内心其实惦记着孩子的，尤其是处于幼儿阶段的宝宝哪怕只是远远的看着也特别满足，\n" +
              "            只差给孩子360度装上监控摄像头了，甚至想时刻跟孩"
        }
      ],
    };
  },
  methods: {
    xw1() {
      this.$router.push('/journalism1')
    },
    load() {
      //滑到底部时进行加载
      this.loading = true;
      setTimeout(() => {
        this.count += 1; //页数+1
        this.list; //调用接口，此时页数+1，查询下一页数据
      }, 2000);
    },
    // getMessage() {
    //   let params = {
    //     pageNumber: this.count,
    //     pageSize: 10 //每页查询条数
    //   };
    //   this.$axios
    //       .post(
    //           "https://接口",
    //           params
    //       )
    //       .then(res => {
    //         console.log(res);
    //         this.list = this.list.concat(res.data.body.content); //因为每次后端返回的都是数组，所以这边把数组拼接到一起
    //         this.totalPages = res.data.body.totalPages;
    //         this.loading = false;
    //       })
    //       .catch(err => {
    //         console.log(err);
    //       });
    // }
  },
  computed: {
    noMore() {
      //当起始页数大于总页数时停止加载
      return this.count >= this.totalPages - 1;
    },
    disabled() {
      return this.loading || this.noMore;
    }
  },
  created() {

  },
}
</script>

<style scoped>
.xw_box {
  width: 82%;
  margin: auto;
  display: flex;
  margin-top: 6vh;
}

.back {
  width: 240px;
  height: 180px;
  /*background:#42b983;*/
}

.xw_tite {
  padding-left: 20px;
}

.cc {
  max-width: 1300px;
  overflow: hidden;
  text-overflow: ellipsis;
  /*禁止换行显示*/
  white-space: nowrap;
}

h2:hover {
  cursor: pointer;
  color: #0077cc;
}

.infinite-list {
  width: 90%;
  height: 300px;
}


</style>